<app-header></app-header>

<div class="page-title-area Blockchain">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <h2 class="typewrite pivotalit">
                    <a href="javascript:void(0)" class="typewrite" data-period="1500" data-type='[ "Blockchain"]'>
                        <!--<span class="wrap">Blockchain</span>-->
                    </a>
                </h2>
            </div>
        </div>
    </div>
</div>
<section class="pt-5">
    <div class="container">
        <div>
            <p> Without question, blockchain is an undeniably ingenious invention and it might not look that different
                from things you’re already familiar with. At a glance, many people can write entries into a record of
                information, and a community of users can control how the record of information is amended and updated
                thanks to blockchain technology. </p>
            <p>SBC has the right team of software developers that can integrate blockchain technologies and help you
                seamlessly and quickly replace your existing ecosystem. We can integrate Bitcoin, Ethereum, Tron,
                Litecoin, Ripple and many others.
            </p>
        </div>
    </div>
    
</section>
<!--Animated common image start-->
<!--Animated common image end-->
<section class="services-details-area position-relative pt-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 services-details">
                <div class="services-details-desc">
                    <h3>Ethereum Blockchain Developer</h3>
                    <p>Ethereum is an open-source, public, blockchain-based distributed computing platform featuring
                        smart contract (scripting) functionality. It provides a decentralized Turing-complete virtual
                        machine, the Ethereum Virtual Machine (EVM), which can execute scripts using an international
                        network of public nodes. Ethereum also provides a cryptocurrency token called “ether”, which can
                        be transferred between accounts and used to compensate participant nodes for computations
                        performed.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 services-details-image">
                <img alt="image" class="wow w-100 fadeInUp" src="assets/assets/images/services-pages/blokchain/1.png" style="visibility: visible; animation-name: fadeInUp;">
            </div>

            <div class="col-lg-12 pt-5  services-details-image">
                <p> Let us help you reduce your financial and transactional dramatically with our solution. Kickstarter,
                    Indiegogo, and others have dominated the crowdfunding space for some years now. A start-up pitches
                    an idea and sets a target for funding. If successful, Kickstarter takes 5%, and passes the rest on
                    to the start-up. On the Ethereum blockchain, a start-up pitches an idea and sets a target for
                    funding. If successful, the smart contract automatically sends the money to the startup and takes 0%
                    as a fee. </p>
            </div>

        </div>
    </div>
</section>

<section class="services-details-area pt-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 services-details-image">
                <img alt="image" class="wow w-100 fadeInUp" src="assets/assets/images/services-pages/blokchain/2.png" style="visibility: visible; animation-name: fadeInUp;"></div>

            <div class="col-lg-6 services-details">
                <div class="services-details-desc">
                    <h3>Bitcoin Blockchain Developer</h3>
                    <p>Bitcoin is history’s first permanent, decentralized, global, trustless ledger of records. Since
                        its invention, entrepreneurs in industries around the world have come to understand the
                        implications of this development. As Bitcoin and other cryptocurrencies have been on fire for a
                        large portion of 2017, focus has turned to blockchain, the underlying technology that powers
                        these digital currencies.</p>

                    <p>Whether it’s Insurance, Peer-to-Peer Transactions, Supply Chain Management, Media, Voting,
                        Cybersecurity or Big Data, SBC has the right team of developers that can integrate state of the
                        art solutions and services.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-details-area pt-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 services-details">
                <div class="services-details-desc">
                    <h3>Ripple Blockchain Developer</h3>
                    <p>It’s the fastest and most scalable digital asset, enabling real-time global payments anywhere in
                        the world. Open-source technology, built on the principles of blockchain with a growing set of
                        validators. </p>
                </div>
            </div>

            <div class="col-lg-6 services-details-image">
                <img alt="image" class="wow w-100 fadeInUp" src="assets/assets/images/services-pages/blokchain/3.png" style="visibility: visible; animation-name: fadeInUp;"></div>


            <div class="col-lg-12 pt-5  services-details-image">
                <p> XRP’s five-year track record of stable technology and governance makes it ready for institutional
                    and enterprise use. Ripple and XRO Provide customers with faster, more transparent and predictable
                    payment services to many corners of the world. Using XRP, banks can source liquidity on demand in
                    real time without having to pre-fund nostro accounts. </p>

                <p>

                    Give us a call today and let us help you get started with the right technology for your business.
                </p>
            </div>


        </div>
    </div>
</section>

<section class="services-details-area pb-5 pt-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 services-details-image">
                <img alt="image" class="wow w-100 fadeInUp" src="assets/assets/images/services-pages/blokchain/4.png" style="visibility: visible; animation-name: fadeInUp;"></div>

            <div class="col-lg-6 services-details">
                <div class="services-details-desc">
                    <h3>Tron Blockchain Developer
                    </h3>
                    <p>Tron in a nutshell is a revolution! It’s a digital asset that aims to revolutionize how In-APP
                        (IAP) currency is used. Not only that it also aims to become a P2P facilitator and Content
                        Provider (OMG, TENX, MCO and STEEM killer), imagine using TRX to good and services online or
                        using TRX to boost your comment on a top post on Twitter, Facebook and the Likes?</p>

                    <p> Our goal is to help you implement a blockchain-based decentralized protocol that aims to
                        construct a worldwide free content entertainment system with the blockchain and distributed
                        storage technology. The protocol allows each user to freely publish, store and own data, and in
                        the decentralized autonomous form, decides the distribution, subscription and push of contents
                        and enables content creators by releasing, circulating and dealing with digital assets, thus
                        forming a decentralized content entertainment ecosystem. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>