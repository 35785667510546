import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm:FormGroup = new FormGroup({
    fullname: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.required),
    phone_number: new FormControl(null, Validators.required),
    subject: new FormControl(null, Validators.required),
    service: new FormControl(null, Validators.required),
    messages: new FormControl(null)
  });
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _router:Router,
    private _userService:UserService,
    private renderer: Renderer2
  ) { }

  refresh() {
    this._router.navigate(['/contact']);
    this.ngOnInit();   
  }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
    this.renderer.addClass(this.document.body, 'startp-nav');
    window.document.title = 'Contact Us - A Software Development Company';
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
  }

  saveContact() {
    const formData = new FormData();
    if(!this.contactForm.valid) {
      if(this.contactForm.value.fullname == null) {
        alert('Please Enter Name!');
      } else if(this.contactForm.value.email == null) {
        alert('Please Enter Email Address!');
      } else if(this.contactForm.value.phone_number == null) {
        alert('Please Enter Phone Number!');
      } else if(this.contactForm.value.subject == null) {
        alert('Please Enter Subject!');
      } else if(this.contactForm.value.service == null) {
        alert('Please Select Service!');
      } else if(this.contactForm.value.messages == null) {
        alert('Please Enter Messages!');
      }
      console.log('Invalid Form');return;
    } else {
      this.renderer.addClass(this.document.body, 'page-loading');
      formData.append('fullname', this.contactForm.value.fullname);
      formData.append('email', this.contactForm.value.email);
      formData.append('phone_number', this.contactForm.value.phone_number);
      formData.append('subject', this.contactForm.value.subject);
      formData.append('service', this.contactForm.value.service);
      formData.append('messages', this.contactForm.value.messages);
    }
    this._userService.postContactAPI(formData).subscribe(
      data=> {
          //var resJson = JSON.stringify(data);
          alert('Your form successfully send.');
          //window.location.reload();
          setTimeout("pageReload();", 500);
        },
      //error=> console.error(error)
    )
    
    //console.log(JSON.stringify(this.stramingForm.value));
  }

}
