import { DOCUMENT } from '@angular/common';
import { Inject, HostListener, Renderer2, Component, OnInit } from '@angular/core';
import {Router, NavigationEnd,ActivatedRoute} from '@angular/router';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  baseUrl: any = [];
  locationUrl: any = [];
  constructor(
      @Inject(DOCUMENT) private document: Document,
      private router: Router,
      private renderer: Renderer2, 
      private activatedRoute: ActivatedRoute, 
      private _userService:UserService
    ) { }

  ngOnInit(): void {
    setTimeout("TextAnimation();", 800);
    this.baseUrl = this._userService.webUrl;
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.baseUrl = this._userService.webUrl;
    //alert('POP ' + document.location);
    this.locationUrl = document.location;
    if(this.locationUrl != this.baseUrl+"#services") {
      //alert('service btn');
      this.renderer.addClass(this.document.body, 'hideBody');
      location.reload();
    }
    //alert('jjj');
  }
  displayChilde(isDisplay = 0) {
    if(isDisplay == 1) {
      (document.querySelector('.dropdown_menu') as HTMLElement).style.display = 'none';
      //alert('aaa');
    } else {
      (document.querySelector('.dropdown_menu') as HTMLElement).style.display = 'block';
    }
  }
  animationEffect() {
    setTimeout("TextAnimation();", 800);
    setTimeout("addStiky();", 1000);
    setTimeout("gotoTopHeader();", 500);
  }
  pageReload() {
    //window.location.reload();
    window.location.href = this.baseUrl;
  }
  openNewPage(pageUrl:any) {
    this.renderer.addClass(this.document.body, 'hideBody');
    //(document.querySelector('.startp-nav') as HTMLElement).style.display = 'none';
    //alert(pageUrl);
    window.location.href = this.baseUrl+'#/'+pageUrl;
    window.scrollTo(0, 0);
    //location.reload();
    //setTimeout("TextAnimation();", 800);
    //setTimeout("gotoTopHeader();", 100);
  }
  showDropMenu() {
    setTimeout("showDropMenu();", 500);
  }
  back(): void {
    //alert('Hi Back');
    location.reload();
    //this.router.navigate('/users')
  }
  loadContactScroll() {
    setTimeout("contactScroll();", 500);
  }

}
