import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {

  baseUrl: any = [];
  blogList: any = [];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _router:Router,
    private renderer: Renderer2,
    private _userService:UserService,
    private _http:HttpClient,
    private route: ActivatedRoute
  ) { }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    //alert('Back button pressed');
    window.location.reload();
  }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
    this.renderer.addClass(this.document.body, 'startp-nav');

    this.baseUrl = this._userService.baseUrl;
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this._http.get(this.baseUrl+'getBlogAPI.php?page_name='+id).subscribe((res: any) => {
        //console.log(res)
        //alert(res)
        if(res == null) {
          this._router.navigate(['/blog']);
        } else {
          this.blogList = res;
          window.document.title = res.title+' - A Software Development Company';
          //this.totalBlogs = Object.keys(this.blogList).length;
          setTimeout("TextAnimation();", 5000);
          //alert('Animation');
        }
      });
    } else {
      this._router.navigate(['/blog']);
    }
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
  }

}
