import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { BlogComponent } from './components/blog/blog.component';
import { CtoAsServiceComponent } from './components/cto-as-service/cto-as-service.component';
import { BlackchainComponent } from './components/blackchain/blackchain.component';
import { CloudComponent } from './components/cloud/cloud.component';
import { ConsultingComponent } from './components/consulting/consulting.component';
import { DevOpsComponent } from './components/dev-ops/dev-ops.component';
import { DevelopementComponent } from './components/developement/developement.component';
import { MobileAppsComponent } from './components/mobile-apps/mobile-apps.component';
import { PivotalComponent } from './components/pivotal/pivotal.component';
import { PivotalDevelopmentComponent } from './components/pivotal-development/pivotal-development.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';

const routes: Routes = [
	{path:'', component: HomeComponent, runGuardsAndResolvers: 'always'},
	{path:'about', component: AboutComponent, runGuardsAndResolvers: 'always'},
	{path:'contact', component: ContactComponent, runGuardsAndResolvers: 'always'},
	{path:'cloud', component: CloudComponent},
	{path:'blog', component: BlogComponent},
	{path:'blog-detail/:id', component: BlogDetailComponent},
	{path:'cto-as-service', component: CtoAsServiceComponent},
	{path:'Blackchain', component: BlackchainComponent},
	{path:'Consulting', component: ConsultingComponent},
	{path:'DevOps', component: DevOpsComponent},
	{path:'Developement', component: DevelopementComponent},
	{path:'MobileApps', component: MobileAppsComponent},
	{path:'pivotal', component: PivotalComponent},
	{path:'PivotalDevelopment', component: PivotalDevelopmentComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
