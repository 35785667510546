<app-header></app-header>

<div class="page-title-area contactPage">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <h2 class="typewrite pivotalit"> 
                    <a href="javascript:void(0)" class="typewrite" data-period="1500" data-type='[ "Contact Us"]'>
                      <!--<span class="wrap"> Contact Us</span>-->
                    </a>
                </h2>
            </div>
        </div>
    </div>
</div>

<section class="contact-info-area ptb-80 contactPage-bottam">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i-feather class="fa fa-envelope-o"></i-feather>
                    </div>
                    <h3>Mail Here</h3>
                    <p><a href="#">sales@southbaycoders.com</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i-feather class="fa fa-map-marker"></i-feather>
                    </div>
                    <h3>Visit Here</h3>
                    <p>12130 Millennium Drive, Playa Vista CA 90094</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <i-feather class="fa fa-phone"></i-feather>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="#">+424 (268) 0304</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-80 contactPage-bottam" id="contactFormSection">
    <div class="container">
        <div class="section-title">
            <h2 style="color:#fff;">Get In Touch With Us</h2>
            <div class="bar"></div>
            <p style="color:#fff;">Anything On your Mind. We’ll Be Glad To Assist You!</p>
        </div>

        <div class="row justify-content-center align-items-center">
            <div class="col-lg-1 col-md-12"></div>
            <div class="col-lg-5 col-md-12">
                <img src="assets/assets/img/contact.png" height="500" width="800" alt="image">
            </div>
            <div class="col-lg-5 col-md-12">
                <form id="contactForm" action="" method="POST" [formGroup]="contactForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="text" formControlName="fullname" id="name" class="form-control" placeholder="Name" />
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="email" formControlName="email" id="email" class="form-control" placeholder="Email" />
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" formControlName="phone_number" id="phone_number" class="form-control" placeholder="Phone" />
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" formControlName="subject" id="msg_subject" class="form-control" placeholder="Subject">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <select formControlName="service" id="service" class="form-control">
                                    <option value="">Select a service </option>
                                    <option value="CTO as a Service for Sartups">CTO as a Service for Sartups</option>
                                    <option value="Mobile Apps Development">Mobile Apps Development</option>
                                    <option value="Web Development">Web Development</option>
                                    <option value="Technology Consulting">Technology Consulting</option>
                                    <option value="Cloud Services">Cloud Services</option>
                                    <option value="DevOps Solutions">DevOps Solutions</option>
                                    <option value="Pivotal IT">Pivotal IT </option>
                                    <option value="Pivotal IT With Development">Pivotal IT With Development</option>
                                    <option value="Blockchain">Blockchain</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea formControlName="messages" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">

                            <button type="button" (click)="saveContact()" class="btn btn-secondary button-styles">Send Message</button>
                        </div>
                        
                    </div>
                </form>
            </div>
            <div class="col-lg-1 col-md-12"></div>
        </div>
    </div>
</section>

<section>
    <div class="container-custom">
        <div class="feature map">
            <iframe width="100%" height="1000" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=1000&amp;hl=en&amp;q=12130%20Millennium%20Drive,%20Playa%20Vista%20CA%2090094+(Software%20development%20company)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
    </div>
</section>

<app-footer></app-footer>