<app-header></app-header>
<div class="page-title-area blogs">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <h2 class="typewrite pivotalit"> 
                    <a href="javascript:void(0)" class="typewrite" data-period="1500" data-type='[ "News"]'>
                        <!--<span class="wrap"> Blogs</span>-->
                    </a>
                </h2>
            </div>
        </div>
    </div>
</div>
<section class="blog-area ptb-80">
    <div class="container">
        <div class="row">

            <div class="col-lg-4 col-md-6" *ngFor="let blog of blogList">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="/blog-detail/{{blog.post_name}}" (click)="animationEffect()">
                            <span *ngIf="(blog.image_url === null || blog.image_url === ''); else elsepart; ">
                                <img src="{{baseUrl}}assets/assets/img/blog-image/1.jpg" alt="{{blog.title}}" />
                            </span>
                            <ng-template #elsepart>
                                <img src="{{blog.image_url}}" alt="{{blog.title}}" />
                            </ng-template>
                        </a>

                        <div class="date">
                            <i-feather name="calendar"></i-feather> {{blog.post_date}}
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="/blog-detail/{{blog.post_name}}" (click)="animationEffect()">{{blog.title}}</a></h3>

                        <p>{{blog.excerpt}}</p>

                        <a href="/blog-detail/{{blog.post_name}}"  class="read-more-btn">Read More <i data-feather="arrow-right"></i> </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<app-footer></app-footer>