import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  baseUrl: any = [];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _router:Router,
    private renderer: Renderer2,
    private _userService:UserService
  ) { }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
    this.renderer.addClass(this.document.body, 'startp-nav');
    window.document.title = 'About Us - A Software Development Company';
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
  }
  animationEffect() {
    setTimeout("TextAnimation();", 800);
    setTimeout("addStiky();", 1000);
    setTimeout("gotoTopHeader();", 500);
  }
  pageReload() {
    //window.location.reload();
    window.location.href = this.baseUrl;
  }
  loadContactScroll() {
    setTimeout("contactScroll();", 500);
  }

}
