import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-developement',
  templateUrl: './developement.component.html',
  styleUrls: ['./developement.component.css']
})
export class DevelopementComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _router:Router,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
    this.renderer.addClass(this.document.body, 'startp-nav');
    window.document.title = 'Developement - A Software Development Company';
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
  }

}
