import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UserService } from 'src/app/user.service';
import { NavigationService } from 'src/app/navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  baseUrl: any = [];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _router:Router,
    private renderer: Renderer2,
    private navigation: NavigationService, 
    private _userService:UserService
  ) { }

  refresh() {
    this._router.navigate(['']);
    this.ngOnInit();   
  }

  ngOnInit(): void {
    this.baseUrl = this._userService.webUrl;
    this.renderer.addClass(this.document.body, 'home-header');
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'startp-nav');
  }

  workCarousel: OwlOptions = {
    loop:true,
		nav:false,
		autoplay: true,
		dots:false,
		responsive:{
			0:{
        items:1,
      },
      768:{
        items:1,
      },
      1200:{
        items:1,
      },
      1500:{
        items:1,
			}
		}
  }
  
  openNewPage(pageUrl:any) {
    this.renderer.addClass(this.document.body, 'hideBody');
    //(document.querySelector('.startp-nav') as HTMLElement).style.display = 'none';
    //alert(this.baseUrl+'#/'+pageUrl);
    window.location.href = this.baseUrl+'#/'+pageUrl;
    window.scrollTo(0, 0);
    //location.reload();
  }  
  back(): void {
    alert('Hi Back');
    this.navigation.back();
    location.reload();
    //this.router.navigate('/users')
  }
  
  loadContactScroll() {
    setTimeout("contactScroll();", 500);
  }
}
