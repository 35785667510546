import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {FileUploadModule} from "ng2-file-upload";
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BlogComponent } from './components/blog/blog.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UserService} from './user.service';
import {AdminService} from './admin.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { CtoAsServiceComponent } from './components/cto-as-service/cto-as-service.component';
import { MobileAppsComponent } from './components/mobile-apps/mobile-apps.component';
import { DevelopementComponent } from './components/developement/developement.component';
import { ConsultingComponent } from './components/consulting/consulting.component';
import { CloudComponent } from './components/cloud/cloud.component';
import { DevOpsComponent } from './components/dev-ops/dev-ops.component';
import { PivotalComponent } from './components/pivotal/pivotal.component';
import { PivotalDevelopmentComponent } from './components/pivotal-development/pivotal-development.component';
import { BlackchainComponent } from './components/blackchain/blackchain.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BlogComponent,
    AboutComponent,
    ContactComponent,
    CtoAsServiceComponent,
    MobileAppsComponent,
    DevelopementComponent,
    ConsultingComponent,
    CloudComponent,
    DevOpsComponent,
    PivotalComponent,
    PivotalDevelopmentComponent,
    BlackchainComponent,
    BlogDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CKEditorModule,
    SocialLoginModule,
    FileUploadModule
  ],
  providers: [UserService, AdminService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '996338480246-ugob92ka62eihf575k2pv531lu5nt7rf.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('633639316672658')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
