<app-header></app-header>
<div class="page-title-area blogs single-blo">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <h2 class="typewrite pivotalit"> 
                    <a href="javascript:void(0)" class="typewrite" data-period="1500" data-type='[ "{{blogList.title}}"]'>
                        <span class="wrap"> {{blogList.title}}</span>
                    </a>
                </h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/assets/img/shape1.png" alt="shape"></div>
    <div class="shape2 rotateme"><img src="assets/assets/img/shape2.svg" alt="shape"></div>
    <div class="shape3"><img src="assets/assets/img/shape3.svg" alt="shape"></div>
    <div class="shape4"><img src="assets/assets/img/shape4.svg" alt="shape"></div>
    <div class="shape5"><img src="assets/assets/img/shape5.png" alt="shape"></div>
    <div class="shape6 rotateme"><img src="assets/assets/img/shape4.svg" alt="shape"></div>
    <div class="shape7"><img src="assets/assets/img/shape4.svg" alt="shape"></div>
    <div class="shape8 rotateme"><img src="assets/assets/img/shape2.svg" alt="shape"></div>
</div>

<section class="blog-area ptb-80">
    <div class="container">
        <div class="row">

            <div class="col-lg-10 col-md-12 offset-lg-1">
                <div class="single-blog-details">
                    <div class="article-img">
                        <span *ngIf="(blogList.image_url === null || blogList.image_url === ''); else elsepart; ">
                        </span>
                        <ng-template #elsepart>
                            <img src="{{blogList.image_url}}" alt="{{blogList.title}}" />
                        </ng-template>
                        <div class="date">{{blogList.post_date}}</div>
                    </div>

                    <div class="article-content" [innerHTML]="blogList.content"></div>
                </div>
            </div>

        </div>
    </div>
</section>
<app-footer></app-footer>