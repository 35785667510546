import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  baseUrl: any = [];
  blogList: any = [];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _router:Router,
    private renderer: Renderer2,
    private _userService:UserService,
    private _http:HttpClient
  ) { }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    //alert('Back button pressed');
    window.location.reload();
  }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
    this.renderer.addClass(this.document.body, 'startp-nav');
    window.document.title = 'News - A Software Development Company';

    this.baseUrl = this._userService.baseUrl;
    this._http.get(this.baseUrl+'getBlogAPI.php').subscribe((res: any) => {
      //console.log(res)
      this.blogList = res;
      //this.totalBlogs = Object.keys(this.blogList).length;
    });
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'home-header');
  }
  animationEffect() {
    setTimeout("TextAnimation();", 1000);
    setTimeout("gotoTopHeader();", 500);
  }

}
