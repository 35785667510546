<app-header></app-header>
<div class="page-title-area aboutbgs">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">

                <h2 class="typewrite pivotalit">
                    <a href="javascript:void(0)" class="typewrite" data-period="1500" data-type='[ "About Us"]'>
                        <!--<span class="wrap"> About us</span>-->
                    </a>
                </h2>

            </div>
        </div>
    </div>
</div>

<section class="solutions-area my-4 py-5 about-bottam">
    <div class="about-area pt-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="ml-about-img">
                        <img class="w-100" alt="image" src="assets/assets/images/about-us.jpg">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <h2 class="about-heading"> About Us</h2>
                        <div class="bar"></div>
                        <p class="about-sub-heading">South Bay Coders was founded February 16 th, 2016 in what is now known as Silicon Beach Santa Monica Our goal is to provide the highest quality Software Coding, Cloud, and App Development services on the globe At South Bay Coders, we are committed to providing superior products, outstanding service, and fair pricing Our desire is to exceed customer expectations, anticipate their needs, show our concern for their time and projects, and stay open and accessible at all times To achieve these goals we are constantly assessing and assembling the needed components be it software or personnel, to serve as the backbone of an organization that will one day help to empower individuals, groups, communities, and governments worldwide.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ml-services-area ptb-80">
    <div class="container">
        <div class="section-title text-center"><h2>Certifications &amp; Qualifications</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-ml-services-box">
                    <div class="image"><img alt="image" src="assets/assets/images/microsoft.png"></div>
                    <h3><a href="javascript:void(0)">Microsoft Certified Developer</a></h3>
                </div>

            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-ml-services-box">
                    <div class="image"><img alt="image" src="assets/assets/images/apple.png"></div>
                    <h3><a href="javascript:void(0)">Apple Certified Developer</a></h3>
                </div>

            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-ml-services-box">
                    <div class="image"><img alt="image" src="assets/assets/images/android.png"></div>
                    <h3><a href="javascript:void(0)">Certified Android Developer</a></h3>
                </div>

            </div>


            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-ml-services-box">
                    <div class="image"><img alt="image" src="assets/assets/images/amazone.jpg"></div>
                    <h3><a href="javascript:void(0)">AWS Certified Solution Architech</a></h3>
                </div>

            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-ml-services-box">
                    <div class="image"><img alt="image" src="assets/assets/images/cci.jpg"></div>
                    <h3><a href="javascript:void(0)"> CISCO Certified Network Expert</a></h3>
                </div>

            </div>


            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-ml-services-box">
                    <div class="image"><img alt="image" src="assets/assets/images/net.jpg"></div>
                    <h3><a href="javascript:void(0)">Netapp Certified Expert</a></h3>
                </div>

            </div>


            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-ml-services-box">
                    <div class="image"><img height="115px" alt="image" src="assets/assets/images/aws1.jpg"></div>
                    <h3><a href="javascript:void(0)"> AWS Certified Developer</a></h3>
                </div>

            </div>


            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-ml-services-box">
                    <div class="image"><img alt="image" src="assets/assets/images/ca1.jpg"></div>
                    <h3><a href="javascript:void(0)">Certified Analystic Professional</a></h3>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-ml-services-box">
                    <div class="image"><img alt="image" src="assets/assets/images/a1.jpg"></div>
                    <h3><a href="javascript:void(0)">Artificial Intelligence Expert</a></h3>
                </div>

            </div>
        </div>

    </div>
</section>

<section class="funfacts-area ptb-80">
    <div class="container">
        <div class="section-title">
            <h2>We have succesfully completed more than 450 Projects since 2016</h2>
            <div class="bar"></div>
            <p>A culture of innovation lies at our very core, we are thinkers and doers, we create new realities that build a brighter futures!</p>
        </div>


        <div class="row">
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <h3> 1M+ </h3>
                    <p> Apps downloaded </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <h3> 200+ </h3>
                    <p> Certifications </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <h3> 35</h3>
                    <p> Workers </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <h3> 150 </h3>
                    <p> Industry Partners</p>
                </div>
            </div>

        </div>        
        <div class="map-bg"><img alt="map" src="assets/assets/images/map.png"></div>
    </div>
</section>
<section class="agency-cta-area ptb-80" #MyDOMElement>
    <div class="container">
        <div class="agency-cta-content">
            <h2>Click below for a free consultation</h2>
            <a routerLink="/contact" class="btn btn-secondary shadow-none" href="javascript:;" (click)="animationEffect();loadContactScroll();">
                Get a Quote</a>
        </div>
    </div>
</section>

<app-footer></app-footer>
