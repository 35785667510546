import { DOCUMENT } from '@angular/common';
import { Inject,  Renderer2, Component, OnInit } from '@angular/core';
import {Router, NavigationEnd,ActivatedRoute} from '@angular/router';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  baseUrl: any = [];
  constructor(
      @Inject(DOCUMENT) private document: Document,
      private router: Router,
      private renderer: Renderer2,
      private activatedRoute: ActivatedRoute,
      private _userService:UserService
  ) { }

  ngOnInit(): void {
    this.baseUrl = this._userService.webUrl;
	//document.getElementById("copyRightYear").innerHTML = new Date().getFullYear();
  }
  animationEffect() {
    setTimeout("TextAnimation();", 1500);
    setTimeout("addStiky();", 1000);
    setTimeout("gotoTopHeader();", 500);
  }
  pageReload() {
    //window.location.reload();
    //window.location.href = this.baseUrl;
  }
  openNewPage(pageUrl:any) {
    //alert(pageUrl);
    this.renderer.addClass(this.document.body, 'hideBody');
    //(document.querySelector('.startp-nav') as HTMLElement).style.display = 'none';
    //alert(pageUrl);
    window.location.href = this.baseUrl+'#/'+pageUrl;
    window.scrollTo(0, 0);
    //location.reload();
    //setTimeout("TextAnimation();", 800);
    //setTimeout("gotoTopHeader();", 100);
  }  
}
