<header class="ng-tns-c4-0" id="header">
    <div class="startp-nav " id="navbar">
        <nav class="navbar navbar-expand-md navbar-light">
            <div class="container">
                <input type="hidden" id="base_urls" value="{{baseUrl}}" />
                <a class="navbar-brand" href="/"><img class="mainlogo pageReload" alt="logo" src="assets/assets/images/Logo-1.png" style="width: 300px;"><img class="home-logo pageReload" alt="logo"  src="assets/assets/images/logo-2.png" style="width: 300px;"></a>
                <button class="navbar-toggler" (click)="showDropMenu()" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" data-target="#navbarSupportedContent" data-toggle="collapse" type="button"><span class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav nav ml-auto">
                        <li class="nav-item active"><a class="nav-link" href="/">Home </a></li>
                        <li class="nav-item"><a class="nav-link" href="/about">About</a></li>
                        <li class="nav-item sub-menu-itm" (mouseover)="displayChilde(0)" (mouseout)="displayChilde(1)">
                            <a class="nav-link" ng-init="displayChilde(1)" href="javascript:void(0)">Services <i-feather class="ng-tns-c4-0 fa fa-chevron-down"></i-feather></a>
                            <ul class="dropdown_menu" style="display: none;">
                                <li class="ng-tns-c4-0"><a class="ng-tns-c4-0" href="/cto-as-service">CTO as a Service for Startups</a></li>
                                <li class="ng-tns-c4-0"><a class="ng-tns-c4-0" href="/MobileApps">Mobile Apps Development</a></li>
                                <li class="ng-tns-c4-0"><a class="ng-tns-c4-0" href="/Developement">Web Development</a></li>
                                <li class="ng-tns-c4-0"><a class="ng-tns-c4-0" href="/Consulting">Technology Consulting</a></li>
                                <li class="ng-tns-c4-0"><a class="ng-tns-c4-0" href="/cloud">Cloud Services</a></li>
                                <li class="ng-tns-c4-0"><a class="ng-tns-c4-0" href="/DevOps">DevOps Solutions</a></li>
                                <li class="ng-tns-c4-0"><a class="ng-tns-c4-0" href="/pivotal">Pivotal IT</a></li>
                                <li class="ng-tns-c4-0"><a class="ng-tns-c4-0" href="/PivotalDevelopment">Pivotal IT With Development</a></li>
                                <li class="ng-tns-c4-0"><a class="ng-tns-c4-0" href="/Blackchain">Blockchain</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a class="nav-link" href="/blog">News </a></li>
                        <li class="nav-item"><a class="nav-link" href="/contact">Contact</a></li>
                    </ul>
                </div>
                <div class="others-option"><a class="btn btn-secondary shadow-none button-styles" href="#" routerLink="/contact" (click)="loadContactScroll();">Get A Quote</a></div>
            </div>
        </nav>
    </div>
</header>