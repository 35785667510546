<app-header></app-header>

<div class="agency-main-banner position-relative home-page">
    <div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="agency-banner-content">
                <span class="sub-title">What We Do</span> 
                <h1 class="typewrite hometype">
                    <a href="javascript:;" class="typewrite" page="home" data-period="1500"
                       data-type='[ "Custom Software Development", "Leverage our CTO on demand", "Looking for mobile app development ", "Need end point protection", "20+ years of IT and dev experience", "Got DevOps?"]'>
                        <!-- <span class="wrap">Custom Software Development</span> -->
                    </a>
                </h1>
                <p class="">South Bay Coders help clients increase efficiency, cut costs, and develop effective IT
                    strategies and solutions that align with their business objectives. Moving fast in todays
                    ecosystems can be a challenge even for the most experienced teams, don’t becoming overwhelmed
                    with the tasks at hand, SBC help you streamline processes, while also improving flexibility,
                    capacity, and efficiency.</p>
                <a href="javascript:;" data-id="#services" class="btn btn-secondary bottomGo button-styles" mat-raised-button color="primary">View Our Services</a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="agency-banner-image">
                <img src="assets/assets/images/bigimage-01.png" loading='lazy' alt="image">
            </div>
        </div>
    </div>
</div>
    <div class="shape10 onLeft"><img src="assets/assets/img/agency-image/shape2.png" alt="image" loading='lazy' /></div>
</div>

<section class="solutions-area ptb-80">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">What We Offer</span>
            <h2>Our Featured Solutions</h2>
            <div class="bar"></div>
            <p>We've come to the realization that a next-generation data center is no longer a nice thing to have; "it's
                a critical part of a strategic business." South Bay Coders can guide you through the process of
                transforming your IT infrastructure from defining business objectives and requirements to selecting,
                implementing and managing your ecosystems to help improve and support your business needs.</p>
        </div>

    </div>
    
    <section class="swiper-container loading">
        <div class="swiper-wrapper">
          <div class="swiper-slide" data-id="cto-as-service" style="background-image:url(assets/assets/images/slide-2.png)">
            <img src="assets/assets/images/slide-2.png" loading='lazy' class="entity-img" />
          </div>
          <div class="swiper-slide" data-id="Developement" style="background-image:url(assets/assets/images/slide-1.png)">
            <img src="assets/assets/images/slide-1.png" loading='lazy' class="entity-img" />
          </div>
          <div class="swiper-slide" data-id="pivotal" style="background-image:url(assets/assets/images/slide-4.png)">
            <img src="assets/assets/images/slide-4.png" loading='lazy' class="entity-img" />
          </div>
          <div class="swiper-slide" data-id="Consulting" style="background-image:url(assets/assets/images/slide-new-2.png)">
            <img src="assets/assets/images/slide-new-2.png" loading='lazy' class="entity-img" />
          </div>
          <div class="swiper-slide" data-id="cloud" style="background-image:url(assets/assets/images/slide-new-1.png)">
            <img src="assets/assets/images/slide-new-1.png" loading='lazy' class="entity-img" />
          </div>
          
        </div>
        <div class="swiper-pagination"></div>	
        <!--<div class="swiper-button-prev swiper-button-white"></div>
        <div class="swiper-button-next swiper-button-white"></div>-->
    </section>
    
    <section class="agency-about-area">
<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
                <img src="assets/assets/images/about4.png" loading='lazy' alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
                <div class="agency-about-content">
                    <span class="sub-title">DevOps with SBC</span>
                    <h2>South Bay Coders Is A Leader In DevOps!</h2>
                    <div class="bar"></div>
                    <p>DevOps methodologies can improve the engineering processes for any organization, regardless of the environment in which your applications and services run. Whether you're an infrastructure, software development, operations or security engineer, learn how DevOps methodologies can help your teams deliver services with speed, stability and availability.</p>
                    <a href="javascript:;" class="btn btn-secondary button-styles showModal1">Why Leverage DevOps?</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="myModal1" class="modal how-we-protect-photo1" style="display:none;">

	<!-- Modal content -->
	<div class="modal-content">
		<span class="close">&times;</span>
	    <h3>DevOps as a Service</h3>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12">
				<p class="text-center"><img src="assets/assets/img/popup-img-1.png" loading='lazy' /></p>
			</div>
		</div>
		<div class="row">	
			<div class="col-lg-12 col-md-12 col-sm-12">
				<div class="single-why-choose-us">
					<p>DevOps methodologies can improve the engineering processes for any organization, regardless of the environment in which your applications and services run. Whether you're an infrastructure, software development, operations or security engineer, learn how DevOps methodologies can help your teams deliver services with speed, stability and availability.</p>
				</div>
			</div>
		</div>
		
		<div class="dna-dpe dpe-as-service">
			<div class="box-center-text-4 wrapper">
				<ul>
					<li class="col-md-3">
						<div class="inner center">
							<div class="eqhight" style="height: 142px;">
								<p><img alt="One click deployments
and rollback" src="assets/assets/images/popup-2/one-click-deployments-and-rollback-configure-automated-alerts.svg" loading='lazy' /></p>
								<p>One click deployments and rollback</p>
							</div>
						</div>
					</li>
					<li class="col-md-3">
						<div class="inner center">
							<div class="eqhight" style="height: 142px;">
								<p><img alt="Configure Automated Alerts" src="assets/assets/images/popup-2/configure-automated-alerts.svg" loading='lazy' /></p>
								<p>Configure Automated Alerts</p>
							</div>
						</div>
					</li>
					<li class="col-md-3">
						<div class="inner center">
							<div class="eqhight" style="height: 142px;">
								<p><img alt="Centralized Log Management" src="assets/assets/images/popup-2/centralized-log-management.svg" loading='lazy' /></p>
								<p>Centralized Log Management</p>
							</div>
						</div>
					</li>
					<li class="col-md-3">
						<div class="inner center">
							<div class="eqhight" style="height: 142px;">
								<p><img alt="Infrastructure Security" src="assets/assets/images/popup-2/infrastructure-security.svg" loading='lazy' /></p>
								<p>Infrastructure Security</p>
							</div>
						</div>
					</li>
					<li class="col-md-3">
						<div class="inner center">
							<div class="eqhight" style="height: 119px;">
								<p><img alt="Continuous Process" src="assets/assets/images/popup-2/continuous-process-and-infrastructure-development.svg" loading='lazy' /></p>
								<p>Continuous Process</p>
							</div>
						</div>
					</li>
					<li class="col-md-3">
						<div class="inner center">
							<div class="eqhight" style="height: 119px;">
								<p><img alt="Continuous Integration" src="assets/assets/images/popup-2/continuous-integration-and-continuous-development.svg" loading='lazy' /></p>
								<p>Continuous Integration</p>
							</div>
						</div>
					</li>
					<li class="col-md-3">
						<div class="inner center">
							<div class="eqhight" style="height: 119px;">
								<p><img alt="Disaster Recovery" src="assets/assets/images/popup-2/disaster-recovery.svg" loading='lazy' /></p>
								<p>Disaster Recovery</p>
							</div>
						</div>
					</li>
					<li class="col-md-3">
						<div class="inner center">
							<div class="eqhight" style="height: 119px;">
								<p><img alt="Performance Optimization " src="assets/assets/images/popup-2/performance-optimization-and-stress-testing.svg" loading='lazy' /></p>
								<p>Performance Optimization</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		
	</div>
</div>

<div class="shape2 rotateme"><img src="assets/assets/img/shape2.svg" alt="shape" loading='lazy' /></div>
<div class="shape3"><img src="assets/assets/img/shape3.svg" alt="shape" loading='lazy' /></div>
<div class="shape6 rotateme"><img src="assets/assets/img/shape4.svg" alt="shape" loading='lazy' /></div>
<div class="shape7"><img src="assets/assets/img/shape4.svg" alt="shape" loading='lazy' /></div>
<div class="shape8 rotateme"><img src="assets/assets/img/shape2.svg" alt="shape" loading='lazy' /></div>
<!--<div class="shape10"><img src="assets/assets/img/agency-image/shape2.png" alt="image"></div>-->
</section>

<section class="agency-about-area agency-about-area-1">
<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
                <img src="assets/assets/images/bigimage-02.png" loading='lazy' alt="image" />
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
                <span class="sub-title">Artificial Intelligence</span>
                <h2>Are you ready to unleash the power of data with AI?</h2>
                <div class="bar"></div>
                <p>Today's businesses collect more data from more sources than ever before about customers, services and systems. Whether you're new to the subject, or you already have a solid understanding of the available technology and are ready to take the next step, we can help you better understand, manage and leverage Big Data effectively. Our Business & Analytics Advisors can make sense of the volumes of information your company stores.</p>
                <a mat-button href="javascript:;" class="play-btn youtube-link" youtubeid="mJeNghZXtMo"> <img alt="image" src="assets/assets/images/play-btn.png" loading='lazy' /></a>
            </div>
        </div>
    </div>
</div>

</section>

<section id="services" class="ml-services-area ptb-80">
<div class="container">

    <div class="section-title">
        <span class="sub-title">Our Solutions</span>
        <h2>Our Services</h2>
        <div class="bar"></div>
        <p>We know you are challenged with delivering a competitive technology advantage while closely managing spend. That's why we've made it easier than ever to find and engage with the right experts at SBC!</p>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-ml-services-box">
                <div class="image"><a href="/Blackchain"><img alt="image" src="assets/assets/images/services-image/img1.png" loading='lazy' /></a></div>
                <h3><a href="/Blackchain">Blockchain</a></h3>
                <p>Our experienced Blockchain engineering teams here at SBC deliver dream-team outcomes non stop!</p>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-ml-services-box">
                <div class="image"><a href="/Developement"><img alt="image" src="assets/assets/images/services-image/img2.png" loading='lazy' /></a></div>
                <h3><a href="/Developement">Web Development</a></h3>
                <p>We create websites that drive traffic, leads and conversions in CMS's like WordPress, Drupal and Hubspot and eCommerce platforms and more!</p>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-ml-services-box">
                <div class="image"><a href="/MobileApps"><img alt="image" src="assets/assets/images/services-image/img3.png" loading='lazy' /></a></div>
                <h3><a href="/MobileApps">Mobile Apps</a></h3>
                <p>SBC deliver custom mobile experiences for mobile devices, wearables, IoT or augmented reality devices.</p>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-ml-services-box">
                <div class="image"><a href="/Consulting"><img alt="image" src="assets/assets/images/services-image/img4.png" loading='lazy' /></a></div>
                <h3><a href="/Consulting">Technology Consulting</a></h3>
                <p>The success of any business starts with the right strategy, we are your source for IT strategy consulting services.</p>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-ml-services-box">
                <div class="image"><a href="/DevOps"><img alt="image" src="assets/assets/images/services-image/img5.png" loading='lazy' /></a></div>
                <h3><a href="/DevOps">DevOps Solutions</a></h3>
                <p>Prepare to witness never-before-seen collaboration between Dev and Ops!</p>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-ml-services-box">
                <div class="image"><a href="/cloud"><img alt="image" src="assets/assets/images/services-image/img6.png" loading='lazy' /></a></div>
                <h3><a href="/cloud">Cloud Services</a></h3>
                <p>Lean on SBC for all your cloud-related challenges, Security & Compliance, Cost Optimization, Availability, Scalability, Manageability and Productivity.</p>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-ml-services-box">
                <div class="image"><a href="/cto-as-service"><img alt="image" style="width: 240px;" src="assets/assets/images/services-pages/cloud/Cloud-Services-01.png" loading='lazy' /></a></div>
                <h3><a href="/cto-as-service">CTO as a Service</a></h3>
                <p>Leverage our CTO as a Service to help you bring the boldest ideas to life!</p>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-ml-services-box">
                <div class="image"><a href="/pivotal"><img alt="image" style="width: 240px;" src="assets/assets/images/services-pages/devops/DevOps-Solutions-01.png" loading='lazy' /></a></div>
                <h3><a href="/pivotal">Pivotal IT</a></h3>
                <p>Leverage tech to transform you businesses to work faster, smarter, and in ways that truly benefit the business. Here's how it works.</p>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-ml-services-box">
                <div class="image"><a href="/PivotalDevelopment"><img alt="image" style="width: 240px;" src="assets/assets/images/services-pages\cloud/t2.jpg" loading='lazy' /></a></div>
                <h3><a href="/PivotalDevelopment">Pivotal IT With Development</a></h3>
                <p>Couple the best of breed technologies to help you manage IT and combine it with most experienced development team on the planet!</p>
            </div>
        </div>

    </div>
</div>
</section>

<section class="agency-cta-area ptb-80">
<div class="container">
    <div class="agency-cta-content">   
        <div class="section-title text-center">
            <h2 class="showModal link-title">How we protect Photo Max Eco </h2>
            <div class="bar"></div>
            <p>Click text above to learn how South Bay Coders Protect Photo Max systems from ransomware , hackers and system failures!</p>
        </div>
    </div>
</div>
</section>

<div id="myModal" class="modal how-we-protect-photo" style="display:none;">

<!-- Modal content -->
<div class="modal-content">
  <span class="close">&times;</span>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
            <img src="assets/assets/img/PhotoMax-01.png" loading='lazy' alt="" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
            <img src="assets/assets/img/VMWare-01.png" loading='lazy' alt="" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-why-choose-us">
                <div class="icon">
                    <a href="https://www.webroot.com/us/en" target="_blank"><img class="img-fluid" src="assets/assets/images/partner-img/webroot1.png" loading='lazy' alt="" /></a>
                </div>

                <h3>Cyber &amp; Security</h3>
                <p>Computer security software is responsible for preventing damage to a device or attacks targeting users.</p>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-why-choose-us">
                <div class="icon">
                    <a href="https://hide.me/en/" target="_blank"><img class="img-fluid" src="assets/assets/images/partner-img/horizontal-blue.png" loading='lazy' alt="" /></a>
                </div>

                <h3>VPN &amp; Service</h3>
                <p>Surf the internet privately and securely while keeping your real IP hidden. Keep your location safe and stop your ISP from spying on you.</p>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-why-choose-us">
                <div class="icon">
                    <a href="https://www.site24x7.com/" target="_blank"><img class="img-fluid" src="assets/assets/images/partner-img/site24x7-1.png" loading='lazy' alt="" /></a>
                </div>

                <h3>All-in-one monitiring solution</h3>
                <p>Performance Monitoring Solution for DevOps and IT Operations; Monitoring Availability and End User Experience Made Simple.</p>
            </div>
        </div>

        <div class="col-lg-6 col-md-6  col-sm-6">
            <div class="single-why-choose-us  mt-4 ">
                <div class="icon">
                    <a href="/pivotal" target="_blank"><img class="img-fluid" src="assets/assets/images/logo.png" loading='lazy' alt="" /></a>
                </div>

                <h3>Small Business Support</h3>
                <p>Get back to focusing on what matters most in the day with managed IT services.</p>
            </div>
        </div>
    </div>
</div>
</div>

<section class="funfacts-area ptb-80">
<div class="container">
    <div class="section-title">
        <h2>We have succesfully completed more than 450 Projects since 2016</h2>
        <div class="bar"></div>
        <p>A culture of innovation lies at our very core, we are thinkers and doers, we create new realities that build a brighter futures!</p>
    </div>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
            <div class="funfact">
                <h3> 1M+ </h3>
                <p> Apps downloaded </p>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
            <div class="funfact">
                <h3> 200+ </h3>
                <p> Certifications </p>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
            <div class="funfact">
                <h3> 35</h3>
                <p> Workers </p>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
            <div class="funfact">
                <h3> 150 </h3>
                <p> Industry Partners</p>
            </div>
        </div>

    </div>

    <div class="map-bg"><img alt="map" src="assets/assets/images/map.png" loading='lazy' /></div>
</div>
</section>

<section class="ml-projects-area pt-4">
<div class="container">
    <div class="section-title">
        <h2 >Proud Projects That Make Us Stand Out</h2>
        <div class="bar"></div>
        <p>We build application and solutions that drive business value and improve quality of life!
            With our beautifull, intuitive designs and stratigic choice in framework, we will arm you with our
            state-of-the-art blueprint that will ultimately lead to your sucess with us!</p>
    </div>
</div>

<div class="container-fluid">
    
    <div class="ml-projects-slides slide owl-carousel owl-theme">
        <div class="slide-box">
        <div class="single-ml-projects-box">
            <img src="assets/assets/images/projects/Doc-Crows.png" alt="image" loading='lazy' />

            <div class="plus-icon">
                <a target="_blank" href="https://www.doccrows.com/">
                    <span></span>
                </a>
            </div>
            </div>
        </div>

        <div class="slide-box">
        <div class="single-ml-projects-box">
            <img src="assets/assets/images/projects/deo.png" alt="image" loading='lazy' />

            <div class="plus-icon">
                <a target="_blank" href="https://deonde.co/">
                    <span></span>
                </a>
            </div>
            </div>
        </div>

        <div class="slide-box">
            <div class="single-ml-projects-box">
                <img src="assets/assets/images/projects/Klio-Studio.png" alt="image" loading='lazy' />

                <div class="plus-icon">
                    <a target="_blank" href="https://www.kliostudio.com/">
                        <span></span>
                    </a>
                </div>
            </div>
        </div>

        <div class="slide-box">
            <div class="single-ml-projects-box">
                <img src="assets/assets/images/projects/3ei.png" alt="image" loading='lazy' />

                <div class="plus-icon">
                    <a target="_blank" href="https://3ei-pi.com/">
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
        <div class="slide-box">
            <div class="single-ml-projects-box">
                <img src="assets/assets/images/projects/SRVE.png" alt="image" loading='lazy' />

                <div class="plus-icon">
                    <a target="_blank" href="https://www.srve.co/">
                        <span></span>
                    </a>
                </div>
            </div>
        </div>

    </div>
</div>

</section>

</section>

  
<section class="ml-services-area Technology-Stack ptb-80">
    <div class="container">
        <div class="section-title text-center"><h2>Our Technology Stack.</h2>
            <div class="bar"></div>
        </div>
    
        <div class="row">
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner twopxtop">
                        <a href="https://www.android.com/intl/en_us/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/android2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/android1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://angular.io/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/angular2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/angular1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.blockchain.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/blockchain-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/blockchain-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner twopxtop">
                        <a href="https://www.dell.com/en-us" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/dell2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/dell1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.docker.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/docker2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/docker1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://flutter.dev/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Flutter2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Flutter1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://cloud.google.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/gcp2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/gcp1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://kubernetes.io/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Kubernetes2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Kubernetes1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://laravel.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/laravel-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/laravel-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.mongodb.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/MongoDB2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/MongoDB1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.microsoft.com/en-us" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Microsoft2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Microsoft1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://puppet.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/puppet-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/puppet-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.netapp.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/NetApp2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/NetApp1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.amazon.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Amazon2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Amazon1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.nginx.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Nginx2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Nginx1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://ubuntu.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Ubuntu2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Ubuntu1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://github.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/partner-5.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/partner-hover5.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://bitbucket.org/product" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Bitbucket-blue2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Bitbucket-blue1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://saltproject.io/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/saltstack2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/saltstack1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.microsoft.com/en-us/windows-server" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/windows-server2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/windows-server1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.php.net/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/php-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/php-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://rubyonrails.org/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/partner-7.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/partner-hover7.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://cassandra.apache.org/_/index.html" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/apache_cassandra-icon3.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/apache_cassandra-icon.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.redhat.com/en" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Redhat2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Redhat1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://reactjs.org/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/react2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/react1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.site24x7.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/site24x7-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/site24x7-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.webroot.com/us/en" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/webroot2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/webroot1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.chef.io/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/chef-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/chef-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.ansible.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/ansible2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/ansible1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.mysql.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/MySQL2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/MySQL1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.splunk.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Splunk2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Splunk1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.tensorflow.org/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/tensorflow-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/tensorflow-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.apache.org/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/apache-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/apache-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.apple.com/us/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/apple-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/apple-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://www.atlassian.com/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/Atlassian-2.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/Atlassian-1.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-3 col-4">
                <div class="logos-colors">
                    <div class="single-ml-partner">
                        <a href="https://nodejs.org/en/" target="_blank">
                            <img alt="image" src="assets/assets/images/partner-img/partner-6.png" loading='lazy' />
                            <img alt="image" src="assets/assets/images/partner-img/partner-hover6.png" loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="agency-cta-area ptb-80">
    <div class="container">
        <div class="agency-cta-content">
            <h2>Click below for a free consultation</h2>
            <a href="#" routerLink="/contact" (click)="loadContactScroll();" class="btn btn-secondary shadow-none button-styles">
                Get a Quote</a>
        </div>
    </div>
</section>

<app-footer></app-footer>