<footer class="footer-area bg-f7fafd">
    <div class="container-fluid px-4">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mrg-top-45">
                    <h3 class="logo"><a href="/"><img alt="logo" src="assets/assets/images/Logo-1.png"></a></h3>
                    <div class="footer-about">At South Bay Coders the help we provide includes a partnership. That means we apply our best practice solutions and expertise to your unique workflow, practice, dynamics business needs. Our next-generation approach ensures best of the class people-process-technology to our client. Give us a call today and partner with a leader in the space of , applications, microservices and solutions!</div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-0">
                    <h3>Company</h3>
                    <ul class="list">
                        <li><a href="/blog">News</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="/Consulting">Technology Consulting</a></li>
                        <li><a href="/cto-as-service">CTO as a Service for Startups</a></li>
                        <li><a href="/Blackchain">Blockchain</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Services</h3>
                    <ul class="list">
                        <li><a href="/pivotal">Pivotal IT</a></li>
                        <li><a href="/PivotalDevelopment">Pivotal IT With Development</a></li>
                        <li><a href="/Developement">Web Developement</a></li>
                        <li><a href="/DevOps">DevOps Solutions</a></li>
                        <li><a href="/cloud">Cloud Services</a></li>
                        <li><a href="/MobileApps">Mobile Apps</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li><i-feather name="map-pin" _nghost-ooo-c5=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-map-pin">
<path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle>
</svg></i-feather> 12130 Millennium Drive, Playa Vista CA 90094</li>
                        <li><i-feather name="mail" _nghost-ooo-c5=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-mail">
<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline>
</svg></i-feather> Email: <a href="mailto:omar@southbaycoders.com">sales@southbaycoders.com</a></li>
                        <li><i-feather name="phone" _nghost-ooo-c5=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-phone">
<path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
</svg></i-feather> Phone: <a href="javascript:void(0)">+424 (268) 0304</a></li>
                    </ul>
                    <ul class="social-links_">
                        <li><a class="facebook" href="https://www.facebook.com/SouthBayCoders/" target="_blank"><img height="auto" src="assets/assets/images/facebook.png" width="279px"></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="copyright-area">
                    <p>Copyright @<span id="copyRightYear"></span>&nbsp;<a class="copyright" href="https://southbaycoders.com/" target="_blank">South Bay Coders</a>. All rights reserved</p>
                </div>
            </div>
        </div>
    </div>
    <img alt="map" class="map" src="assets/assets/img/map.png" />
    <div class="shape1"><img alt="shape" src="assets/assets/img/shape1.png" /></div>
    <div class="shape8 rotateme"><img alt="shape" src="assets/assets/img/shape2.svg" /></div>
</footer>
